@-ms-viewport{
    width: device-width;
  }

@media screen and (min-width: 320px) and (max-width:768px){
.order-sm-2 {
    order: 2 !important;
}
.order-sm-1 {
    order: 1 !important;
}
.order-sm-3 {
    order: 3 !important;
}
.order-sm-4 {
    order: 4 !important;
}
.order-sm-5 {
    order: 5 !important;
}
.order-sm-6 {
    order: 6 !important;
}
.order-sm-7 {
    order: 7 !important;
}
.order-sm-8 {
    order: 8 !important;
}
.order-sm-9 {
    order: 9 !important;
}
.order-sm-10 {
    order: 10 !important;
}
  }
  @media screen and (min-width: 576px) {
    .swiper-container {
      width: 576px;
    }
  }
  
  @media screen and (min-width: 768px) {
    .swiper-container {
      width: 768px;
    }
  }
  @media (min-width: 992px) {
    .progressbar .swiper .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22.2% !important;
    }
  }
@media screen and (min-width: 320px) and (max-width:768px){
    .enhanced{padding-top: 0;}
    .container, .container-sm {max-width: 95% !important;}
    .banner-bg img.leftanimation, .banner-bg img.rightanimation{display: none;}
    .banner-bg h2{font-size: 46px;word-wrap: break-word;}
    .banner-bg.min-heght p, .banner-bg.bg-transparentcustm p{padding: 0;font-size: 15px;}
    .banner-bg img.banner2{top: 42px;right: 0;}
    .banner-bg p {font-size: 15px;padding: 0 38px;}
    .banner-bg h2.home-pd {padding: 120px 9px 30px;font-size: 45px;}
    .banner-bg img {right: 0px;height: auto;width: 100%;z-index: 0;z-index: -1;}
    .banner-bg.min-heght img{right: 0px;height: auto;z-index: 0;}
    .banner-bg p br{display: none;}
    .highrisk-inner-pic ul li{width: calc(100% / 1);margin: 12px 0;}
    .pin-spacer{display: none !important;}
    .desktopContent{width: 100%;}
    .hmrgtpic, .left {display: block;}
    .tcbpayinfobx-inner{padding:45px 0 0;}
    .tcbpayinfobx-inner h4 {font-size: 38px;}
    .featuresbx h6{font-size: 38px;text-align: left;}
    .hmrgtpic{margin: 40px 0 0;}
    .featuresbx-inr .inr-box{margin-bottom: 15px;}
    .featuresbx-inr .inr-box h4 {margin: 12px 0 12px;}
    .querybx{width: 90%;padding: 40px 33px;}
    .icon-img-170{margin:14px auto;}
    .share-icon.d-flex{margin: 20px auto 0;justify-content: space-evenly !important;}
    .share-icon a{margin: 0;}
    footer .social-text li a {font-size: 14px;}
    .ontop .md-mb50 {width: 49% !important;}
    .pb-80 {padding-bottom: 0 !important;}
    .our-integration-inner.text-center, 
    .our-integration-inner h6.text-center,
    .our-integration-inner p.text-center{text-align: left !important;}
    .our-integrationsbx{padding: 50px 0;}
    .calendly-inline-widget {height: 1032px;margin-bottom: 0;}
    .m-visible{display: block;margin: 20px 0;}
    .featuresbx-inr .row > [class*='col-'] {display: inline-block;}
    .featuresbx-inr .row .mt-60{margin-top: 30px !important;}
    .allin-onepayment {padding: 50px 0;}
    .tracking-facility .tracking-inr-box {width: 100%;flex: inherit;margin-bottom: 20px;padding: 0 0 0 0 !important;}
    .partner-ppic{width: 100%;}
    .slider-swiper{display: block;}
    .slider-swiper .swiper {height: auto;width: 80%;}
    .streamlined-picr {max-width: 100%;
        min-width: 100%;}
    .honest-area.gradient-background1{background: transparent;height: auto;}
    .honest-area {padding: 80px 0 0px;margin-bottom: 0;}
    .nutshell.gradient-background2{background: transparent;height: auto;margin:0 0 50px;}
    .honest-lftbx {box-shadow: none;background: transparent;}
    .honest-lftbx-inner p{margin-bottom: 18px;}
    .honest-lftbx-inner {background: #fff;border-radius: 10px;margin: 0 0 20px;border-right: 0;box-shadow: 0px 3px 6px #00000029;width: calc(100% / 1);}
    .pricingdetail-btn{margin: 17px auto;}
    .enhanced {padding: 0 0 70px;}
    .enhanced-yellowdots {height: 287px;right: 0;bottom: -28px;}
    .streamlined-bx {padding: 50px 0 0;}
    .featuresbx{padding: 72px 0 50px;}
    .apptab {padding: 0 0 0;}
    .apptab p br{display: none;}
    .our-integration-inner.p-80{padding: 0 0 50px;}
    .payportal-area.gradient-background3{height: auto;}
    .itsolution-contact {padding: 0 0 170px;}
    .newtcbpayapp-icn1,.newtcbpayapp-icn2,.newtcbpayapp-icn3,.newtcbpayapp-icn4{display: none;}
    .ourtrm p{padding: 0;}
    .ourteam .d-flex{display: inline-block !important;}
    .custm-transactionbx {padding: 0 0 0;}
    .partner-benefits {margin: 50px 0 50px;}
    .featuresbx .mt-40{margin-top: 0 !important;}
    .security-list ul li{display: inline-block !important;}
    .security-list-lft {width: 100%;margin: 0 0 0 0;}
    .security-list-rgt {width: 100%;margin: 20px 0 0;}
    .security-list-lft img {width: 25px;margin: 0 15px 0 0;}
    .banner-bg span {padding: 120px 0 20px;}
    .tcbpayapp {padding: 50px 0 0;}
    .payportal-area {padding: 180px 0 20px;margin-bottom: 60px;}
    .it-contact {padding: 0 13px;}
    .appplayicn img {width: 140px;}
    .circle {width: 100%;}
    .codehim img {height: 164px;width: 164px;}
    .marquee:before{background: transparent;}
    .slider-swiperbx{min-height: auto;margin: 0 0 20px;}
    .transaction-area{margin-bottom: 40px;}
    .max-wd5{margin: 30px 0 0;}
    .effort-area-bottom::after{background-position: top center;top:0px;height: 1380px;}
    .effort-area {padding: 70px 0 0;}
    .effort-area .left-side-img {margin: 90px auto 0;}
    .effort-area-bottom{padding: 70px 0 0;height: 1000px;}
    .nutshell-list .deepyellow .heading {padding: 12px 22px;}
    .whitelabel-product{margin:90px 0 0;}
    .table-wrapper table thead{display: inline-flex;background: #EFEFEF;border-radius: 10px;width: 100%;padding: 0;justify-content: space-evenly;}
    .table-wrapper table th .heading {color: #397AB7;font-size: 15px;}
    .table-wrapper table th:nth-child(1){display: none;}
    .table-wrapper table th, .table-wrapper table td{width: auto;min-width: auto;}   
    .table-wrapper table td {
        padding: 10px;
        font-size: 16px;
        display: inline-block;
        width: 25%;
        text-align: center;border-right: 1px solid #d1d1d1;
    }
    .table-wrapper table td:last-child{border: 0;}
    .table-wrapper table td:first-child {border-right: 0;display: block;width: 100%;}
    .table-wrapper table tr {display: block;}
    .ecommerce-bx {padding: 137px 0 100px;margin-bottom: 40px;margin-top: 40px;}
    .ecommerce-bx.gradient-background9{height: auto;}
    .blue-dots2 {height: 350px;}
    .retail-bg:after{height: 300px;bottom: 200px;}
    .desktopPhoto1{margin: 20px 0 0;}
    .mb-180 {margin-bottom: 40px;margin-top: 60px;}
    .high-riskbg{height: 1400px;padding: 120px 0 0 0;text-align: left;margin: 0 0 -248px;}
    .highrisk-bg::before{top:100px}
    .highrisk-bg{padding-bottom: 0;}
    .itsolution-icn5, .itsolution-icn6, .itsolution-icn7, .itsolution-icn8,
    .itsolution-icn1, .itsolution-icn2, .itsolution-icn3, .itsolution-icn4{display: none;}
    .security-list.intrelist li a {display: inline-block;position: relative;}
    .security-list.intrelist .arw {position: absolute;right: 0;top:0;}
    .security-list{padding: 60px 0 45px;}
    .security-bx .security-list{padding: 100px 0 150px;}
    .security-bx .security-list.intrelist{ padding: 60px 0 45px;}
    .our-integration-inner h6 {font-size: 36px;}
    .integrations-logo ul li{width: calc(100% / 2);margin: 25px 0px 25px;text-align: left;justify-content: left;}
    .integrations-logo ul li img {width: 84%;}
    .counter-bx .col-md-3{width: calc(100% / 2)}
    .counter-bx .counter-txt{font-size: 40px;margin-top: 40px;}
    .counter-bx {margin: 37px 0 0;}
    .itsolution-area {padding: 105px 0 100px;margin: 40px 0 0;}
    .itsolution-business .text-center, .itsolution-business .itbusiness {text-align: left !important;}
    .featuresbx.itsolution-business {padding: 50px 0 50px;}
    .new-contactbx .container{max-width: 100% !important;padding: 0.70rem;}
    .its-area.gradient-backgroundit{display: none;}
    .new-contactbx{background:url(/public/assets/imgs/itfrm-bg.svg) no-repeat ;margin: 0;background-size: cover;padding: 120px 0 124px;height: 100%;}
    .streamlined-bx .ps-4{padding-left: 0 !important;margin-top: 20px;}
    .achp-bg::before{min-height: 375px;top: -270px;}
    .achp-bg{margin-top: 90px;padding-top: 30px;}
    .effort-area .left-side-img img {width: 90%;}
    .ach-mt{margin: 20px 0 0;}
    .abut-area.gradient-background5{min-height: 598px;margin-top:50px;margin-bottom: 30px;}
    .abut-area {padding: 140px 0 100px;}
    .abut-area1.gradient-background6{min-height: 658px;}
    .abut-area1 {padding: 100px 0 100px;}
    /* .abut-seclast .swiper-slide {width: 100% !important;} */
    .abut-iconbx{margin: 0 28px 0 0;}
    .abut-iconbx img {width: 78px;height: 78px;}
    .abut-seclast-inner {align-items: flex-start;}
    .abut-iconbxtxt {font-size: 15px;}
    .slider-swiper1 .progress-bar-container{margin-top: 0 !important;}
    .banner-bg.bg-transparentcustm.career-bg{padding-bottom: 90px;}
    .faq-innerbx .row > [class*='col-'] {width: 100%;}
    .searchbar-txt{font-size: 50px;}
    .banner-bg .faqdots {position: absolute;z-index: 0;height: auto;right: 3px;}
    .form-group.has-search {padding: 2.375rem 0.3rem;}
    .faq-bx {margin: 20px 0;}
    .featuresbx.partner-spin{overflow: hidden;margin: 0 0 -155px;}
    .codehim{display: none;}
    .featuresbx.partner-spin .p-dots{display: none;}
    .circle li:nth-child(5) {top: -127px;left: 0;}
    .circle li:nth-child(6) {top: -66px;left: 0;text-align: left;display: inline-flex;}
    .circle li:nth-child(7) {top:13px;left: 0;}
    .circle li:nth-child(4) {top: 71px;left: 0;}
    .circle li:nth-child(3) {top: 131px;left: 0;}
    .circle li:nth-child(8) {bottom: 265px;left: 0;}
    .circle li:nth-child(2) {bottom: 200px;left: 0;}
    .circle li:nth-child(10) {bottom: 137px;left: 0;}
    .circle li:nth-child(9) {bottom: 76px;left: 0;}
    .circle li:nth-child(6) br{display: block;}

    .circle li {max-width: 432px;white-space: nowrap;overflow: hidden;
        animation: linear infinite;
        /* animation-name: move-up-left ; */
        animation-duration: 7s;
    }
    @keyframes move-up-left  {
        0% {
          left: 0;
        }
        50% { 
          left: calc(100% - 300px); 
         }
        100% {
          left: 0;     
        }
      }

    .fnt-800 {text-align: left;font-size: 50px;}
    .fnt-800 .pp-img{display: none;}
    .payportal-area.padding-custmprc .mb-40{margin: 0 0 10px ;}
    .honest-lftbx-inner span{padding: 0 0 40px;}
    .honest-lftbx .honest-lftbx-inner p{font-size: 15px;}
    .honest-rgtbx{padding: 30px;}
    .pp-contentbxin, .pp-contentbxin ul li:nth-child(odd) {width: 100%;}
    .pp-contentbxin ul{display: inline-block;width: 100%;border-bottom: 0;}
    .blue-dots4{z-index: -1;right: -14px;}
    .pp-contentbxin ul{background: transparent;}
    .pp-contentbxin ul li:nth-child(even){width: 100%;padding: 20px 30px;border-radius: 10px;}
    .pp-contentbxin ul li:nth-child(even) span {width: auto;margin: 0 0 0 10px;}
    .listpp1 ul{background: transparent;}
    .listpp1 ul li{padding: 5px 0 !important;}
    .orng-dots3{right: -14px;}
    .pp-contentbx {padding: 90px 0 50px;}
    #accordionExample{display: block;}
    .pricing-column-wrapper{display: none;}
    .pricing-column {display: flex;margin-bottom: 0;}
    .pricing-column .pp-pic {margin: 0 13px 0 0px;min-height: inherit;}
    #accordionExample .accordion-item:nth-child(odd) .accordion-button{box-shadow: none;background-color:#FDF1D8;border:0;border-radius: 10px;}
    #accordionExample .accordion-item:nth-child(even) .accordion-button{box-shadow: none;background-color:#DDEEFF;border:0;border-radius: 10px;}
    #accordionExample .accordion-item:nth-child(even) .pricing-listbx .listpp ul li::before {
        width: 20px;
        height: 15px;
        content: '';
        display: inline-block;
        background:url(/public/assets/imgs/pp-circle1.svg) no-repeat;
    }
    #accordionExample .accordion-item:nth-child(even) .pricing-listbx .eachtitle {color: #397AB7;}
    #accordionExample .accordion-item{border-radius: 10px;margin-bottom: 20px;border:1px solid #FDF1D8;}
    #accordionExample .accordion-item:nth-child(even){border-radius: 10px;border:1px solid #DDEEFF;}
    #accordionExample .accordion-item:nth-child(odd) .accordion-button:not(.collapsed)::after{
        content: '\f0d7 ';
        background-image: none;
        color: #FECA61;
        font-family: 'FontAwesome';
        transform: rotate(0deg);
        font-size: 22px;
        margin-top: -9px;
        text-align: right;
    }
    #accordionExample .accordion-item:nth-child(odd) .accordion-button::after {
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: auto;
        content: '\f0d7 ';
        background-image: none;
        margin-top: -9px;
        color: #FECA61;
        font-family: 'FontAwesome';
        font-size: 22px;
        transform: rotate(-89deg);
        background-image: none;
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform .2s ease-in-out;
    }
    #accordionExample .accordion-item:nth-child(even) .accordion-button:not(.collapsed)::after {
        content: '\f0d7 ';
        background-image: none;
        color: #397AB7;
        font-family: 'FontAwesome';
        transform: rotate(0deg);
        font-size: 22px;
        margin-top: -9px;
        text-align: right;
    }
    #accordionExample .accordion-item:nth-child(even) .accordion-button::after {
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: auto;
        content: '\f0d7 ';
        background-image: none;
        margin-top: -9px;
        color: #397AB7;
        font-family: 'FontAwesome';
        font-size: 22px;
        transform: rotate(-89deg);
        background-image: none;
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform .2s ease-in-out;
    }
    .cdots{display: none;}
    #contactForm .row>*{padding: 0;}
    .contactus-bx.gradient-backgroundfull {padding: 110px 0 0;}
    .mvcontainer.container, .mvcontainer .row, .mvcontainer .col-lg-6{padding: 0;max-width: 100% !important;margin: 0;}
    .contact-infobx {margin: 20px 0 20px;}
    .pp-contentbx.pb-180{padding-bottom: 180px;}
    .contact-bx{margin-bottom: 20px;}
    .menu-hidden-sm{display: none !important;}
    .navbar-expand-md .d-block{display: flex !important;}
    .sidebar-nav.groupaside.mb-faq, .mb-faq{display: none;}
    .form-group.has-search1{display: none;}
    .creadit-api-upper-wrapper{margin:70px 0 0;}
    .credit-api-main {padding: 112px 0 200px;}
    .faq-leftsectitle {font-size: 20px;}
    #faqaccordionExample{display: block;position: absolute;left: -32px;width: 118%;}
    #faqaccordionExample .accordion-item .accordion-button:not(.collapsed)::after {/*content: '\f0d7 ';background-image: none;color: #397AB7;font-family: 'FontAwesome';*/background: url(/public/assets/imgs/arrows-menus.svg) no-repeat;flex-shrink: 0;width:15px;height:15px;transform: rotate(90deg);margin-top: 0px;text-align: right;}
    #faqaccordionExample .accordion-item .accordion-button::after {background: url(/public/assets/imgs/arrows-menus.svg) no-repeat;flex-shrink: 0;width:15px;height:15px;margin-left: auto;/*font-family: 'FontAwesome';font-size: 22px;transform: rotate(-89deg);content: '\f0d7 ';*/
        margin-top:0;color: #397AB7;transition: transform .2s ease-in-out;}
    #faqaccordionExample .accordion-item .accordion-button{box-shadow: none;background-color:#DDEEFF;border:0;border-radius: 10px;}
    #faqaccordionExample .accordion-item{border:0;border-radius: 10px 10px 20px 20px;}
    .accordion-collapse.collapse.show{box-shadow: 0px 3px 6px #00000029;border-radius: 0 0 10px 10px !important;margin: -20px 0 0 0;
    padding: 25px 0 0 0;background: #fff;height: 400px;overflow-y: scroll;scrollbar-width: thin;}
    #collapse7.accordion-collapse.collapse.show{margin: 0 0 0 0;position: relative;overflow: hidden;overflow-y: scroll;padding:0 0;}
    #collapse7 .accordion-body{padding: 8px 2rem;}
    .sidebar-link.active div{display: inline-block;}
    .sidebar-nav.groupaside{margin-bottom: 0;}
    .sidebar-linkmb{font-weight: bold;color: #397AB7;font-size: 15px;margin: 0 0 0 18px;}
    .sidebar-linkmb img, .sidebar-linkmb svg{margin: 0 10px 0 0;}
    .faqissuingpic{margin: 0;}
    .legal-bx {padding: 120px 0 150px;}
    .legal-bx #faqaccordionExample {display: block;position: absolute;left: 0px;width: 100%;}
    .legal-bx .nav-pills{display: none;}
    .terms_and_conditions-sec, .privacy_policy-sec, .payfac-conditions-sec, 
    .code-of-conduct-sec, .cookie_policy-sec, .personal-guarantee-sec {padding-left: 0;margin-top: 80px;}
    .legal-bx .d-flex{display: inline-block;}
    .legal-bx .tab-content .container{padding-left: 0;padding-right: 0;--bs-gutter-x: 0;}
    .legal-bx .container{padding-left: 10px;padding-right: 10px;}
    .tc-pp-cc-cp-pg .title-sec h1{font-size: 38px;}
    .tc-pp-cc-cp-pg .title-sec h1 span {padding-bottom: 15px;margin-bottom: 0px;}
    #faqaccordionExample .sidebar-item .nav-link{font-size: 15px;color: #000;font-weight: 600;text-align: left;}
    #faqaccordionExample .sidebar-nav.groupaside{padding-top:15px;}
    .faq-srchbx{width: 97%;}
    .banner-bg.bg-transparentcustm.systmbg{min-height: auto;margin-bottom: 40px;}
    .system-accodianbx{margin-bottom: 50px;width: auto;}
    .system-accodianbx .accordion-button{font-size: 16px;padding: 15px 46px 15px 15px;}
    .incident-list-wrap .incident-action-row:last-child {height: min-content;}
    .mobile-menuoffcanvas{width: 100%;display: inline-block;}
    .mobile-menuoffcanvas ul{margin: 0;padding: 0;width: 100%;display: inline-block;}
    .mobile-menuoffcanvas ul li{border-bottom:1px solid #EFEFEF;font-size: 15px;font-weight:700;
        color: #397AB7;padding: 15px 20px;background:url(/public/assets/imgs/menu-arw.svg) no-repeat;background-position: 93% center;}
    .mobile-menuoffcanvas ul li:last-child{background: none;border: 0;}
    .offcanvas, .offcanvas.show{max-width: 100.5% !important;width: 100.5%;}
    .offcanvas-body.mobile-v{padding: 0;}
    .offcanvas-body.mobile-v .dropdown-item img{width: 22px;margin: 0 12px 0 0;}
    .EZDrawer .EZDrawer__container{width: 100% !important;}
    .offcanvas-header{width: 100%;padding: 10px 30px 32px !important;}
    .navbar.mb{overflow-y: scroll;height: 95%;overflow-x: hidden;padding: 0 0 150px 0;align-items: flex-start;}
    .navbar.mb .navbar-nav .megamenubg{ padding: 25px 0 0;width: 100%;}
    .offcanvas-body.mobile-v .menutitle{padding: 0 30px 10px;font-size: 14px;}
    .fxdbtn-mb{background: #fff;position: absolute;bottom: 0;z-index: 999;width: 100%;text-align: center;}
    .back-mbbtn{font-size: 15px;font-weight: 600;color:#777777;display: inline-flex;border: 0;background: transparent;align-self: flex-start;margin: 20px 0 0 30px;}
    .back-mbbtn img{margin: 2px 5px 0 0;width: 15px;}
    .menu-inner {padding: 15px 0 15px 65px;}
    .navbar .megamenubg .dropdown-item span {margin-left: 36px;}
    .offcanvas-backdrop.show{opacity: 0 !important;}
    .blue-dots3 {right: 88px;}
    .contct-mb{font-size: 15px;font-weight: 700 !important;color: #397AB7 !important;padding: 15px 20px !important;}
    .navbar .megamenubg .dropdown-item {padding-left: 30px;}
    .navbar .megamenubg .dropdown-item:hover{color: #397ab7;background: transparent;}
    .navbar .megamenubg .dropdown-item:hover span{color: #000;}
    .navbar .megamenubg .menu-inner .dropdown-item:hover{color: #397ab7;background: transparent;}
    .navbar .megamenubg .menu-inner .dropdown-item:hover span{color: #000;}
    .offcanvas-header .btn-close{width: 15px;border-radius: 0;
        height: 15px;padding: 0 11px 0 0 !important;background:url(/public/assets/imgs/mb-cls.svg) no-repeat;opacity: 1;}
    .offcanvas-header .btn-close:focus{outline: 0; box-shadow: none;}
    .signin{background: #CDDDED;color: #397AB7;font-size: 14px;font-weight: bold;padding: 12px 25px;border-radius: 50px;width: max-content;float: right;margin: 0 27px 0 0;}
    .mobile-menuoffcanvas1{width: 100%;display: inline-block;margin: 20px 0 0 0;}
    .mobile-menuoffcanvas1 ul{margin: 0;padding: 0;width: 100%;display: inline-block;}
    .mobile-menuoffcanvas1 ul li{border-bottom:1px solid #EFEFEF;font-size: 15px;font-weight:700;
        color: #000000;padding: 18px 30px;background:url(/public/assets/imgs/menu-arw.svg) no-repeat;background-position: 93% center;}
    .mobile-menuoffcanvas1 ul li img{width: auto;margin: 0 10px 0 0;}
    .mobile-menuoffcanvas1 ul li a{font-size: 15px;font-weight:700;color: #000000;}
    .mobile-menuoffcanvas1 ul li a span{font-size: 15px;font-weight:700;color: #397AB7;}
    .teamlist {display: block;}
    .banner-bg img.blue-dotscareer{z-index: -1;}
    .mblegal-mnu .accordion-item .accordion-button{background-color: #EFEFEF !important;border-radius: 0 !important;}
    .payfac-terms-and-conditions{margin: 80px 0 0 0;}
    .payfac-terms-and-conditions span,
    .terms_and_conditions-sec span,
    .code-of-conduct-sec span,
    .cookie_policy-sec span,
    .personal-guarantee-sec span{max-width: 100% !important;}
    .tab-content{width: 100%;}
    .text2 a {word-break: break-word;}
    .tc-pp-cc-cp-pg p{word-break: break-word;}
    .signin{margin: 0 25px 45px 0;background: #CDDDED;font-size: 14px;color: #397AB7;font-weight: bold;border-radius: 50px;width: max-content;padding: 12px 22px;float: right;}
    #fill-tab-example {width: 91%;border-radius: 0;border: 2px solid #397AB7;border-left: 0;border-right: 0;display: flex;overflow:hidden;scrollbar-width: thin; overflow-x: scroll;
        margin:0 33px;height: 62px;flex-wrap: nowrap;}
    #fill-tab-example li{margin: 0 20px 0 0;display: flex;white-space: nowrap;}
    #fill-tab-example li:last-child{margin: 0;}
    #fill-tab-example .nav-link{padding: 10px 20px;}
    #fill-tab-example::-webkit-scrollbar {width: 2px;}
    #fill-tab-example::-webkit-scrollbar-track {background: #f1f1f1; }
    #fill-tab-example::-webkit-scrollbar-thumb {background: #888; }
    #fill-tab-example::-webkit-scrollbar-thumb:hover {background: #555; }
    .mb-custmwd{    width: 100%;}
    .apptab .tab-content{border-radius: 0;padding:0 ;}
    .md-custmwd1{background: #fff;margin: 80px 0 50px;padding: 80px 0 40px;}
    .custmtab-pic {margin: -37px 0 ;z-index: 1;position: relative;}
    .apptab .tab-container-mb{margin: 0;padding: 0;max-width: 100% !important;}
    .tab-content .custmtab{width: 88%;}
    .banner-bg, .min-heght{margin-bottom: 70px;}
    .apitoggle#api_accordionExample{display: none;}
    #api-accordion{display: block;}
    .credit-api-main #api-accordion {
        display: block;
        position: absolute;
        left: 0px;
        width: 100%;z-index: 12;
        background: #fff;transition-duration: 400ms ;
        transition-property: all ;
    }
    .apitoggle-mb .accordion-item .accordion-button {
        background-color: #EFEFEF !important;border: 0;
        box-shadow: none;border-radius: 0 !important;padding: 18px 36px;font-size: 15px;font-weight: bold;color: #397AB7;
    }
    #api-accordion #collapse7 .accordion-item .accordion-button::after{content: none;}
    #api-accordion #collapse7 .accordion-item .accordion-button{background-color: transparent !important;}
    #api-accordion #collapse7 .accordion-collapse.collapse.show {
        box-shadow: none;
        border-radius: 0 0!important;
        margin: 0;
        padding: 0;height: auto;overflow: hidden;
    }
    #api-accordion .accordion-item .accordion-button::after {content:'';
        background: url(/public/assets/imgs/arrows-menus.svg) no-repeat;flex-shrink: 0;width:15px;height:15px;margin-left: auto;/*font-family: 'FontAwesome';font-size: 22px;transform: rotate(-89deg);content: '\f0d7 ';*/
        margin-top:0;color: #397AB7;transition: transform .2s ease-in-out;
    }
    #api-accordion .accordion-item .accordion-button:not(.collapsed)::after {
        /*content: '\f0d7 ';
         background-image: none;
        color: #397AB7;
        font-family: 'FontAwesome';
        transform: rotate(0deg);
        font-size: 22px;
        margin-top: -9px;
        text-align: right; */
        content: '';
        background: url(/public/assets/imgs/arrows-menus.svg) no-repeat;flex-shrink: 0;width:15px;height:15px;transform: rotate(90deg);margin-top: 0px;text-align: right;
    }
    
    .api-menu-url li .card-url-list {display: inline-block;}
    .api-menu-url li .card-url-list p{width: 100%;}
    .card-url li .card-url-list span a{margin-left: 0;}
    .creadit-api-lower-wrapper{margin-bottom: 0;}
    .example-box-wrapper.mt-100{margin-top: 0 !important;}
    .credit-api-main .stickdynamic{position: inherit !important;}
    .api-menu #heading7 .accordion-button::before{content: none;}
    .ex-text-box pre, .table-responsive{scrollbar-width: thin;}
    .creadit-api-upper-wrapper h1{font-size: 36px;}
    .card-url{margin: 20px 0 0;}
    .apidetail-btn{position: absolute;top: 90px;}
    .example-box-wrapper .example-box .example-box-top {width: 107%;overflow: hidden;}
    .integration-btn{width: 300px;}
    .offcanvas-title .navbar-brand img{width: 90%;}
    .card-url li .card-url-list {display: block;word-break: break-all;}
    .api-table table tbody tr td {padding: 12px;}
    .api-table table{filter: blur(.0px);}
    button:focus{border:0;outline: 0;}
    .history-header .pagination .next-page, .history-header .pagination .previous-page{width: 50px;}
    .mb-systxt{margin: 40px 0 0;}
    .history-header{margin-top: 0;}
    .faq-lft .accordion-collapse.collapse.show{height: auto;background: transparent;box-shadow: none;overflow-y: initial;}
    .tracking-facility.d-sm-block{display: none !important;}
    .it-mb{margin-bottom: 0;}
    .menutitle.yelw + .dropdown-item:hover{color:#F0A611}
    .spchgt .tch2.dropdown-item:hover{color:#F0A611}
    .spchgt .menu-inner .dropdown-item:hover{color:#F0A611 !important}
    .spchgt1 .dropdown-item{padding-bottom: 20px;}
    .mb-bgtab{background:transparent linear-gradient(253deg, #B0D5F7 0%, #e5f1fa 100%) 0% 0% no-repeat padding-box;}
    .faq-lft .accordion-item:last-of-type, .faq-lft .accordion-item:first-of-type{border-radius: 10px;}
    .apidetail-btn svg{padding: 4.5px 5px;}
    .efrt-bg::after{top: -100px;}
    .efrt-bg{padding: 0px 0 0;}
    .partner-swiperbx img {margin: -12px;width: 107%;}
    .accordionpp#accordionExample .accordion-collapse.collapse.show{height: auto;box-shadow: none;}
    .fnt-800 .pp-img.icnpp{display: inline-block;}
    .accordiontcbpotal#accordionExample .accordion-collapse.collapse.show{height: auto;box-shadow: none;padding: 0;background: transparent;}
    .nutshell-list .skyblue, .nutshell-list .orange, .nutshell-list .deepyellow, .nutshell-list .deepblue{background: transparent;}
    .accordiontcbpotal#accordionExample .accordion-item:nth-child(even), .accordiontcbpotal#accordionExample .accordion-item:nth-child(odd){border: 0;}
    .accordiontcbpotal .pricing-row-title{color: #fff;padding: 0;}
    .accordiontcbpotal .accordion-body{padding:10px 0 0 0;}
    .pp-contentbxin.flex-startnw{background: #fff;}
    .pp-contentbxin ul li:nth-child(2n+1).border-radiuscustm2{background: transparent !important;}
    .accordiontcbpotal#accordionExample .accordion-item .accordion-button:not(.collapsed)::after{
        content: '\f0d7 ';
        background-image: none;
        color: #fff;
        font-family: 'FontAwesome';
        transform: rotate(0deg);
        font-size: 22px;
        margin-top: -9px;
        text-align: right;
    }
    .accordiontcbpotal#accordionExample .accordion-item .accordion-button::after {
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: auto;
        content: '\f0d7 ';
        background-image: none;
        margin-top: -9px;
        color: #fff;
        font-family: 'FontAwesome';
        font-size: 22px;
        transform: rotate(-89deg);
        background-image: none;
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform .2s ease-in-out;
    }
    .nutshell-list{margin: 0;}
    .integration-btn1.mb-integration{width: auto;}
    .listintri {margin: 0 0 30px 10px;}
    .system-accodianbx .accordion-collapse.collapse.show {box-shadow: none;border-radius:10px;background: transparent;height: auto;}
    .pagination .current {font-size: 12px;}
    .our-integration-inner .integration-btn{width: 280px;}
    .integration-btn4.mt-80{margin-top: 40px !important;}
    .fade.offcanvas-backdrop.show{height: 0;}
    .dksmls{display: none !important;}
    .navbar .navbar-nav .megamenubg.spchgt .dropdown-item.tch2:last-child{padding-bottom: 20px;}
    .dropdown-item.active, .dropdown-item:active, 
    .dropdown-item:focus, .dropdown-item:hover{background-color: transparent !important;}
    .inr-box1.innerwht p br{display: block;}
    .it-contact p br{display: none;}
    .accordiontcbpotal#accordionExample .accordion-item {border-radius: 13px;}
    .mb-bgtab .custmtab-pic{padding: 0 0 0 23px;}
    .itmb-20{margin-bottom: 30px;padding-right: 30px;}
    .order-none{display: none;}
    .order-block{display: block;}
    .security-list-lft img.icn32{width: 25px !important;}
    .security-list-lft img.icn27{width: 21px !important;}
    .alert-section .mobile-sec{padding-left: 0;}
    .alert-section .image_wrapper img{margin: 0 0 30px;}
    .alert-section .blue_box{margin: 0;padding: 39px 24px 10px;}
    .banner-bg.min-heght .banner2{width: 100% !important;margin: 0 0 30px;}
    .mbdnone{display: none;}
    .listpp2 ul li::before{height: 51px;}
    .apibg{width: 100%;}
    #faqaccordionExample .accordion-collapse.collapse.show{margin: 0;padding: 10px 0 0 0;}
}
@media screen and (min-width: 1400px) and (max-width:1499px){.faq-srchbx{width: 629px;}}
@media screen and (min-width: 1500px) and (max-width:1780px){
.honest-area.gradient-background1 {
    height: 808px;
    padding: 400px 0 100px;
}
.retail-bg:after{bottom: 372px;}
.effect-1:hover .effect-text.yelw{padding: 88px 30px 0;}
.effect-1:hover .effect-text{padding: 88px 30px 0;}
.gradient_area::after {
    position: absolute;
    width: 100%;
    bottom: -235px;
    height: 1150px;
}
.gradient-banner-inr {
    position: relative;
    z-index: 5;
    padding-bottom: 220px;
}
.alert-section .image_wrapper img {
    width: 110%;
}
.navbar .navbar-nav .megamenu .dropdown-menu{max-width: 1325px;}
.banner-bg{margin-bottom: 120px;min-height: 820px;}
.high-riskbg{margin: 0px 0 0}
.banner-bg.career-bg{min-height: 920px;}
.careerbg{width: 32%;}
.partner-swiperbx img {
    margin: -10px;
    width: 106%;
}
.partnerpg{width: 97%;}
.effort-area-bottom::after{height: 85em;}
.its-area {
    position: relative;
    padding: 150px 0 150px;
    overflow: hidden;
    height: 52em;
    z-index: -1;
}
.new-contactbx {
    padding: 0 0;
    position: relative;
    margin: -730px 0 0;
}
.partner-ppic{width:87%;}
.itsolution-icn3{position: absolute;top: 300px;}
.itsolution-icn4{position: absolute;top: 400px;}
.faq-srchbx{width: 629px;}
}
@media screen and (min-width: 1781px) and (max-width:2560px){
    .banner-bg.alert{margin-bottom: 0;min-height: 870px;}
    .banner-bg{margin-bottom: 120px;min-height: 820px;}
    .banner-bg.bg-transparentcustm{min-height: 556px; max-height: 556px;margin-bottom: 0px;}
    .banner-bg img.leftanimation{width: 370px;min-width: 32%;}
    .banner-bg img.rightanimation{width: 34%;}
    .honest-area.gradient-background1{height: 951px;}
    .honest-area{padding: 490px 0 100px;}
    .nutshell.gradient-background2 {
        height: 1140px;
        position: relative;
        margin: -834px 0 100px;
    }
    .payportal-area.gradient-background3{height:1086px;}
    .payportal-area {padding: 530px 0 100px;}
    .abut-area.gradient-background5{min-height: 950px;}
    .abut-area {padding: 387px 0 221px;}
    .abut-area1.gradient-background6{min-height: 886px;}
    .abut-area1 {padding: 267px 0 200px;}
    .alertnotify.gradient-background8 {height: 1300px;}
    .alertnotify {padding: 20% 0 100px;}
    .ecommerce-bx.gradient-background9{height: 990px; margin: -37px 0 140px 0;position: relative;z-index: -1;}
    .ecommerce-bx {padding: 410px 0 100px;}
    .transaction-area {padding: 285px 0 200px;}
    .transaction-area.gradient-background7 {height: 1380px;}
    .itsolution-area {
        padding: 400px 0 200px;
        overflow: hidden;
        min-height: 980px;
        margin: -56px 0 0;
        z-index: -1;
        position: relative;
    }
    .retail-bg:after{height: 1105px;bottom: 449px;    background-size: cover;}
    .m-top200 {margin-top: -406px;}
    .effect-1:hover .effect-text.yelw{padding: 88px 30px 0;}
    .effect-1:hover .effect-text{padding: 88px 30px 0;}
    .gradient_area::after {
        position: absolute;
        width: 100%;
        bottom: -235px;
        height: 1150px;
    }
    .gradient-banner-inr {
        position: relative;
        z-index: 5;
        padding-bottom: 220px;
    }
    .alert-section .image_wrapper img {
        width: 120%;
    }
    .navbar .navbar-nav .megamenu .dropdown-menu{max-width: 1325px;}
    .highrisk-bg::before{top: -160px;}
    .high-riskbg {
        margin: 0 0;
        height: 80em;
        padding: 28em 0px 160px;
    }
    .banner-bg.career-bg{min-height: 920px;}
.careerbg{width: 32%;}
.partner-swiperbx img{margin: -10px;}
.partnerpg{width: 97%;}
.effort-area-bottom::after{height: 80em;}
.effort-area-bottom {
    min-height: 757px;
    margin: 0 0 36em;
}
.its-area {
    position: relative;
    padding: 150px 0 150px;
    overflow: hidden;
    height: 58em;
    z-index: -1;
}
.new-contactbx {
    padding: 0 0;
    position: relative;
    margin: -730px 0 0;
}
.partner-ppic{width: 87%;}
.itsolution-icn3{position: absolute;top: 300px;}
.itsolution-icn4{position: absolute;top: 400px;}
.faq-srchbx{width: 629px;}
.progressbar .swiper .swiper-slide {
    width: 19.7% !important;
}
}
@media screen and (min-width: 2000px) and (max-width:2560px){
    .banner-bg{margin-bottom: 120px;min-height: 820px;}
    .banner-bg img.leftanimation{width: 370px;min-width: 29%;bottom: -30px;}
    .banner-bg img.rightanimation {width: 30%;bottom: -10px;}
    .highrisk-bg::before{top: -250px;height: 1029px;}
    .gradient_area::after {
        position: absolute;
        width: 100%;
        bottom: -235px;
        height: 1250px;
    }
    .gradient-banner-inr {
        position: relative;
        z-index: 5;
        padding-bottom: 300px;
    }
    .alert-section .image_wrapper img {
        width: 120%;
    }
    .high-riskbg {
        margin: 0 0;
        height: 80em;
        padding: 28em 0px 160px;
    }
    .itsolution-icn3{position: absolute;top: 300px;}
    .itsolution-icn4{position: absolute;top: 400px;}
    .progressbar .swiper .swiper-slide {
        width: 19.3% !important;
    }
}
@media screen and (min-width: 2561px) and (max-width:2900px){
    .banner-bg.alert{margin-bottom: 0;min-height: 870px;}
    .banner-bg{margin-bottom: 240px;min-height: 980px;}
    .banner-bg img.leftanimation{width: 370px;min-width: 29%;}
    .banner-bg img.rightanimation {width: 30%;}
    .banner-bg.bg-transparentcustm{min-height: 556px; max-height: 556px;margin-bottom: 50px;}
    .honest-area.gradient-background1{height: 951px;}
    .honest-area{padding: 490px 0 100px;}
    .nutshell.gradient-background2 {
        height: 1440px;
        position: relative;
        margin: -1044px 0 100px;
    }
    .payportal-area.gradient-background3{height:1086px;}
    .payportal-area {padding: 630px 0 100px;}
    .abut-area.gradient-background5{min-height: 950px;}
    .abut-area {padding: 387px 0 221px;}
    .abut-area1.gradient-background6{min-height: 886px;}
    .abut-area1 {padding: 267px 0 200px;}
    .alertnotify.gradient-background8 {height: 1700px;}
    .alertnotify {padding: 20% 0 100px;}
    .ecommerce-bx.gradient-background9{  height: 1100px;      
        margin: -132px 0 140px 0;
        position: relative;
        z-index: -1;}
    .ecommerce-bx {padding: 530px 0 100px;}
    .transaction-area {padding: 315px 0 200px;}
    .transaction-area.gradient-background7 {height: 1380px;}
    .itsolution-area {
        padding: 480px 0 200px;
        overflow: hidden;
        min-height: 1100px;
        margin: -56px 0 0;
        z-index: -1;
        position: relative;
    }
    .retail-bg:after{height: 1315px;bottom: 648px;    background-size: cover;}
    .m-top200 {
        margin-top: -430px;
    }
    .effect-1:hover .effect-text.yelw{padding: 88px 30px 0;}
.effect-1:hover .effect-text{padding: 88px 30px 0;}
.gradient_area::after {
    position: absolute;
    width: 100%;
    bottom: -235px;
    height: 1300px;
}
.gradient-banner-inr {
    position: relative;
    z-index: 5;
    padding-bottom: 330px;
}
.alert-section .image_wrapper img {
    width: 120%;
}
.navbar .navbar-nav .megamenu .dropdown-menu{max-width: 1325px;}
.highrisk-bg::before{height: 1075px;top: -360px;}
.high-riskbg {
    margin: 0px 0;
    height: 80em;
    padding: 28em 0px 130px;
}
.banner-bg.career-bg{min-height: 890px;}
.careerbg{width: 32%;}
.partner-swiperbx img{margin: -10px;}
.partnerpg{width: 97%;}
.effort-area-bottom::after{height: 107em;}
.effort-area-bottom {
    margin: 0 0 36em;
}
.its-area {
    position: relative;
    padding: 150px 0 150px;
    overflow: hidden;
    height: 60em;
    z-index: -1;
}
.new-contactbx {
    padding: 0 0;
    position: relative;
    margin: -730px 0 0;
}
.itsolution-icn1 {top: -64px;}
.itsolution-icn2 {top: 39px;}
.partner-ppic{width:87%;}
.itsolution-icn3{position: absolute;top: 280px;}
.itsolution-icn4{position: absolute;top: 380px;}
.faq-srchbx{width: 629px;}
.progressbar .swiper .swiper-slide {
    width: 18.7% !important;
}
}    
@media screen and (min-width: 2901px) and (max-width:3200px){
    .banner-bg.alert{margin-bottom: 0;min-height: 870px;}
    .highrisk-bg::before {
        height: 1207px;
        top: -440px;
    }

    .banner-bg{margin-bottom: 240px;min-height: 980px;}
    .banner-bg img.leftanimation{width: 370px;min-width: 29%;}
    .banner-bg img.rightanimation {width: 30%;}
    .banner-bg.bg-transparentcustm{min-height: 556px; max-height: 556px;margin-bottom: 50px;}
    .honest-area.gradient-background1{height: 951px;}
    .honest-area{padding: 490px 0 100px;}
    .nutshell.gradient-background2 {
        height: 1440px;
        position: relative;
        margin: -1044px 0 100px;
    }
    .payportal-area.gradient-background3{height:1086px;}
    .payportal-area {padding: 630px 0 100px;}
    .abut-area.gradient-background5{min-height: 950px;}
    .abut-area {padding: 387px 0 221px;}
    .abut-area1.gradient-background6{min-height: 886px;}
    .abut-area1 {padding: 267px 0 200px;}
    .alertnotify.gradient-background8 {height: 1700px;}
    .alertnotify {padding: 20% 0 100px;}
    .ecommerce-bx.gradient-background9{  height: 1100px;      
        margin: -132px 0 140px 0;
        position: relative;
        z-index: -1;}
    .ecommerce-bx {padding: 530px 0 100px;}
    .transaction-area {padding: 315px 0 200px;}
    .transaction-area.gradient-background7 {height: 1380px;}
    .itsolution-area {
        padding: 550px 0 200px;
        overflow: hidden;
        min-height: 1200px;margin: -56px 0 0;
        z-index: -1;
        position: relative;
    }
    .retail-bg:after{height: 1335px;bottom:705px;    background-size: cover;}
    .m-top200 {
        margin-top: -430px;
    }
    .effect-1:hover .effect-text.yelw{padding: 88px 30px 0;}
.effect-1:hover .effect-text{padding: 88px 30px 0;}
.gradient_area::after {
    position: absolute;
    width: 100%;
    bottom: -235px;
    height: 1450px;
}
.gradient-banner-inr {
    position: relative;
    z-index: 5;
    padding-bottom: 450px;
}
.alert-section .image_wrapper img {
    width: 125%;
}
.navbar .navbar-nav .megamenu .dropdown-menu{max-width: 1325px;}
.our-integrationsbx {background-position: top left;}
.high-riskbg {
    margin: 0 0;
    height: 80em;
    padding: 28em 0px 160px;
}
.banner-bg.career-bg{min-height: 920px;}
.careerbg{width: 32%;}
.partnerpg{width: 97%;}
.effort-area-bottom::after{height: 85em;}
.its-area {
    position: relative;
    padding: 150px 0 150px;
    overflow: hidden;
    margin-top: -450px;
    height: 59em;
    z-index: -1;
}
.new-contactbx {
    padding: 0 0 300px;
    position: relative;
    margin: -430px 0 0;
}
.partner-ppic{width: 87%;}
.faq-srchbx{width: 629px;}
.progressbar .swiper .swiper-slide {
    width: 17.7% !important;
}
} 
@media screen and (min-width: 3201px) and (max-width:3500px){
    .banner-bg.alert{margin-bottom: 0;min-height: 870px;}
    .highrisk-bg::before {
        height: 1207px;
        top: -440px;
    }
    .banner-bg {height: 830px;margin-bottom: 0;}
    .banner-bg.bg-transparentcustm{min-height: 556px; max-height: 556px;margin-bottom: 50px;}
    .banner-bg img.leftanimation{width: 34%;bottom: 0;}
    .banner-bg img.rightanimation{width: 34%;}
    .honest-area.gradient-background1{height: 951px;}
    .honest-area{padding: 490px 0 100px;}
    .nutshell.gradient-background2 {
        height: 1440px;
        position: relative;
        margin: -1044px 0 100px;
    }
    .payportal-area.gradient-background3{height:1086px;}
    .payportal-area {padding: 630px 0 100px;}
    .abut-area.gradient-background5{min-height: 950px;}
    .abut-area {padding: 387px 0 221px;}
    .abut-area1.gradient-background6{min-height: 886px;}
    .abut-area1 {padding: 267px 0 200px;}
    .alertnotify.gradient-background8 {height: 1700px;}
    .alertnotify {padding: 20% 0 100px;}
    .ecommerce-bx.gradient-background9{  height: 1100px;      
        margin: -232px 0 0 0;
        position: relative;
        z-index: -1;}
    .ecommerce-bx {padding: 530px 0 100px;}
    .transaction-area {padding: 315px 0 200px;}
    .transaction-area.gradient-background7 {height: 1380px;}
    .itsolution-area {
        padding: 550px 0 200px;
        overflow: hidden;
        min-height: 1200px;margin: -56px 0 0;
        z-index: -1;
        position: relative;
    }
    .retail-bg:after{height: 1335px;bottom:705px;    background-size: cover;}
    .m-top200 {
        margin-top: -430px;
    }
    .effect-1:hover .effect-text.yelw{padding: 88px 30px 0;}
.effect-1:hover .effect-text{padding: 88px 30px 0;}
.gradient_area::after {
    position: absolute;
    width: 100%;
    bottom: -235px;
    height: 1450px;
}
.gradient-banner-inr {
    position: relative;
    z-index: 5;
    padding-bottom: 450px;
}
.alert-section .image_wrapper img {
    width: 125%;
}
.navbar .navbar-nav .megamenu .dropdown-menu{max-width: 1325px;}
.our-integrationsbx {background-position: top left;}
.high-riskbg {
    margin: 0 0;
    height: 80em;
    padding: 28em 0px 160px;
}
.banner-bg.career-bg{min-height: 920px;}
.careerbg{width: 32%;}
.partnerpg{width: 97%;}
.effort-area-bottom::after{height: 85em;}
.its-area {
    position: relative;
    padding: 150px 0 150px;
    overflow: hidden;
    height: 59em;
    z-index: -1;
}
.new-contactbx {
    padding: 0 0 300px;
    position: relative;
    margin: -430px 0 0;
}
.faq-srchbx{width: 629px;}
.progressbar .swiper .swiper-slide {
    width: 16.7% !important;
}
} 